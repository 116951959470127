import React, { useState, useEffect } from "react";
import "./App.css";
import OwlCarousel from "react-owl-carousel";
import smlogo from "./smlogomobile.png"
import img1 from "./111.png";
import LastCard from "./components/Footer/LastCard";
import Footer from "./components/Footer/Footer";
import Reels from "./components/Media/Reels";
import OrangeSection from "./components/orangesection/OrangeSection";
import AboutSection from "./components/About Us/AboutSection";
import arrow from "../src/Group 9.png";
import invest from "../src/investment.png";
import playButton from "../src/videoplay.png";
import Loader from "./components/Loader/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import unlock1 from '../src/unlock1.jpg';
import unlock2 from '../src/unlock2.jpg';
import unlockv1 from '../src/unlockv1.mp4';
import unlockv2 from '../src/unlockv2.mp4';

export default function App() {
 
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
 
  const [value, setValue] = useState(700);
  const [activeRange, setActiveRange] = useState(525);
  const [defaults, setDefaults] = useState(false);
  const [defaultscolor, setDefaultscolor] = useState(false);
  const [shaking, setShaking] = useState(false);

  const [showMenu, setShowMenu] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);
  

 

  useEffect(() => {
    setScreenLoading(true);
    setTimeout(() => {
      setScreenLoading(false);
    }, 2500);
  }, []);



  useEffect(() => {
    const intervalId = setInterval(() => {
      setShaking(true);
      setTimeout(() => {
        setShaking(false);
      }, 500); // Duration of the shake animation in milliseconds
    }, 7000); // Interval between each shake animation in milliseconds

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const setOtpbtn = (e) => {
    e.preventDefault();
    toast.success('File Downloading...');
    
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://naad-dev.katsamsoft.com/api/method/naad.api.send_otp?customer_name=${fullName}&mobile_no=${phoneNumber}&sq_feet=${activeRange}`,
          {
            method: "POST",
            headers: {
              Cookie:
                "full_name=Guest; sid=Guest; system_user=yes; user_id=Guest; user_image=",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log(data); // Handle the response data here

        const verifyData = async () => {
          try {
            const response = await fetch(
              `https://naad-dev.katsamsoft.com/api/method/naad.api.verify_otp?mobile=${phoneNumber}&size=${activeRange}`,
              {
                method: "POST",
                headers: {
                  Cookie:
                    "full_name=Guest; sid=Guest; system_user=yes; user_id=Guest; user_image=",
                },
              }
            );
    
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
    
            const data = await response.json();
            window.open(data.message, '_blank');


            // setCount(3);
            // setIsDownloading(true);
            // downloadPDF(pdfLink);
          } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
          }
        };

        verifyData();

        setDefaults(true);
        setDefaultscolor(true);
        
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchData();
  };

  const setRange = (range) => {
    setActiveRange(range);
  };

  

  

  

  const options = {
    autoplay: false,
    autoplayTimeout: 3000, // 3 seconds
    loop: true,
    margin: 0,

    responsive: {
      0: {
        items: 2,
        margin: 30,
      },
      600: {
        items: 2,
        margin: 20,
      },
      1000: {
        items: 4,
      },
    },
  };
  return (
    <>
      {screenLoading ? (
        <Loader />
      ) : (
        <>
      <main className="hero-section">
        <div>
          <nav className="navbar navbar-expand-lg navbar-light">
            <span className="d-md-block d-sm-none d-none">
              <img src={smlogo} className="img-fluid logo1" alt="One Percent Scheme"/>
            </span>
            <span className="d-sm-block d-md-none d-flex flex-row">
              <img src={smlogo} className="img-fluid logo1" alt="One Percent Scheme"/>
              <div className="col-6 border-start oneee">
                One <br></br>Percent
              </div>
            </span>

            {/* Mobile */}
            {/* <div className="d-sm-block d-md-none">
              <nav className="nav container">
                <div className={`nav__menuu ${showMenu ? "show-menu" : ""}`} id="nav-menu">
                <div className="nav__close" id="nav-close" onClick={toggleMenu}>
                    <img src={smlogo} className="img-fluid logo1" />
                      <p className="col-8 border-start border-black onee">One <br></br>Percent</p>
                      <IoClose />
                    </div>
                  <ul className="nav__list">
                    <li className="nav__item">
                      <a href="#why" className="nav__link" onClick={closeMenuOnMobile}>
                       Why Us
                      </a>
                      
                    </li>
                    <li className="nav__item">
                      <a href="#about" className="nav__link" onClick={closeMenuOnMobile}>
                        About Us
                      </a>
                      
                    </li>
                    <li className="nav__item">
                      <a href="#amenities" className="nav__link" onClick={closeMenuOnMobile}>
                        Amenities
                      </a>
                    </li>
                    <li className="nav__item">
                      <a href="#madhur" className="nav__link" onClick={closeMenuOnMobile}>
                        Madhur Dreams
                      </a>
                    </li>
                    <li className="nav__item-1">
                      <a href="#last" className="nav__link" onClick={closeMenuOnMobile}>
                        Investors
                      </a>
                    </li>
                  </ul>
                  
                </div>

                <div
                  className="nav__toggle"
                  id="nav-toggle"
                  onClick={toggleMenu}
                >
                  <IoMenu />
                </div>
              </nav>
            </div> */}

            {/* Desktop */}
            <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarTogglerDemo02"
            >
              <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                <li className="nav-item active">
                  <div className="container-ani">
                    <a className="nav-link" href="#why">
                      Why Us
                      {/* <div className="nav-animation"></div> */}
                      <div className="line"></div>
                      <div className="box"></div>
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="container-ani">
                    <a className="nav-link" href="#about">
                      About Us
                      <div className="line"></div>
                      <div className="box"></div>
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="container-ani">
                    <a className="nav-link" href="#amenities">
                      Amenities
                      <div className="line"></div>
                      <div className="box"></div>
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="container-ani">
                    <a className="nav-link" href="#madhur">
                      Madhur Dreams
                      <div className="line"></div>
                      <div className="box"></div>
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="container-ani">
                    <a className="nav-link" href="#last">
                      Investors
                      <div className="line"></div>
                      <div className="box"></div>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div className="row">
          <div className="banner-caption">
            {/* <h6 className="highlight">
              <u>NEW SCHEME</u>
            </h6> */}
            <h5>
              BUY your <br></br> dream<br></br> property by <br></br>Investing
              JUST <br></br>
              <span className="orange">1% PER MONTH</span>
            </h5>
          </div>
        </div>
        <div className="arrow-div">
          <div className="nav-arrow">
            <img src={arrow} alt="One Percent Scheme" />
          </div>
          <div className="center">
            <div className="circle pulse orange-col"></div>
          </div>
        </div>
      </main>
      <div className="container-fluid orange-bg" id="orange-sec">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center justify-content-center">
            <div className="img-container-why" id="why">
              <h5 className="about-stoketext">
                {" "}
                UNLOCK O
                <span className="">
                  <span className="per "></span>NE PERCENT
                </span>
              </h5>
            </div>
            <div className="outersect">
              <div className="container">
                <div className="row">
                  <span className="circleorange"></span>
                  <div className="col-md-6 col-sm-12">
                    <div className="videocard">
                      <img src={unlock2} className="img-fluid" alt="One Percent Scheme"/>
                      <img src={playButton} alt="One Percent Scheme" className="play-button"  data-bs-toggle="modal"
                data-bs-target="#exampleModal2" />     
                    </div>
                    <div className="caption">
                      <p className="videotext">
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="videocard">
                      <img src={unlock1} className="img-fluid" alt="One Percent Scheme"/>
                      <img src={playButton} alt="One Percent Scheme" className="play-button"  data-bs-toggle="modal"
                data-bs-target="#exampleModal1" />     
                    </div>
                    <span className="circleorange1"></span>

                    <div className="caption">
                      <p className="videotext">
                      </p>
                    </div>
                  </div>
                  {/* <div className="bgback"></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-black">
        <div className="container-fluid">
          <div className="row"></div>
        </div>

        <div className="container">
          <div className="row">
            <OwlCarousel className="owl-theme" {...options}>
              <div className="circle-container1">
                <div className="circle1"></div>
                <div className="content">
                  <h6>
                    <span>LOWEST</span> <br></br>
                    <span className="orangetext">EMI RATES</span>
                  </h6>
                </div>
              </div>
              <div className="circle-container1">
                <div className="circle1"></div>
                <div className="content">
                  <h6>
                    <span>EASY</span> <br></br>
                    <span className="orangetext"> APPROVAL</span>
                  </h6>
                </div>
              </div>
              <div className="circle-container1">
                <div className="circle1"></div>
                <div className="content">
                  <h6>
                    <span>JUST 1%</span> <br></br>
                    <span className="orangetext"> PER MONTH</span>
                  </h6>
                </div>
              </div>
              <div className="circle-container1">
                <div className="circle1"></div>
                <div className="content">
                  <h6>
                    <span>BEST </span> <br></br>
                    <span className="orangetext"> AMENITIES</span>
                  </h6>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>

        <div className=""></div>

        <div
          className={`vibrating-img ${
            shaking ? "sticky-box pulse shake" : "sticky-box pulse"
          }`}
        >
          <div className="slider-container">
            <h5 className="text-center">Download Your Payment Plan</h5>
            <div className="divider"></div>

            <div className="d-md-block d-sm-none d-none">
              <div className="btn-container">
                <button
                  style={{ color: "#000" }}
                  className={activeRange === 525 ? "orangebtn" : ""}
                  onClick={() => setRange(525)}
                >
                  525 Sq
                </button>
                <button
                  style={{ color: "#000" }}
                  className={activeRange === 600 ? "orangebtn" : ""}
                  onClick={() => setRange(600)}
                >
                  600 Sq
                </button>
                <button
                  style={{ color: "#000" }}
                  className={activeRange === 850 ? "orangebtn" : ""}
                  onClick={() => setRange(850)}
                >
                  850 Sq
                </button>
                <button
                  style={{ color: "#000" }}
                  className={activeRange === 1100 ? "orangebtn" : ""}
                  onClick={() => setRange(1100)}
                >
                  1100 Sq
                </button>
                <button
                  style={{ color: "#000" }}
                  className={activeRange === 1500 ? "orangebtn" : ""}
                  onClick={() => setRange(1500)}
                >
                  1500 Sq
                </button>
              </div>
            </div>

            <div className="d-sm-block d-md-none">
              <div className="btn-container">
                <button
                  style={{ color: "#000" }}
                  className={activeRange === 525 ? "orangebtn" : ""}
                  onClick={() => setRange(525)}
                >
                  525 Sq
                </button>
                <button
                  style={{ color: "#000" }}
                  className={activeRange === 600 ? "orangebtn" : ""}
                  onClick={() => setRange(600)}
                >
                  600 Sq
                </button>
                <button
                  style={{ color: "#000" }}
                  className={activeRange === 850 ? "orangebtn" : ""}
                  onClick={() => setRange(850)}
                >
                  850 Sq
                </button>
              </div>
              <div className="btn-container">
                <button
                  style={{ color: "#000" }}
                  className={activeRange === 1100 ? "orangebtn" : ""}
                  onClick={() => setRange(1100)}
                >
                  1100 Sq
                </button>
                <button
                  style={{ color: "#000" }}
                  className={activeRange === 1500 ? "orangebtn" : ""}
                  onClick={() => setRange(1500)}
                >
                  1500 Sq
                </button>
              </div>
            </div>

            <div className="d-flex justify-content-center fancy-button medium alizarin down">
              
              <button className="buttonsvg">
                <svg
                  className="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 33"
                  fill="none"
                >
                  <path
                    d="M8.15147 31.8723C8.6201 32.341 9.3799 32.341 9.84853 31.8723L17.4853 24.2356C17.9539 23.7669 17.9539 23.0071 17.4853 22.5385C17.0167 22.0699 16.2569 22.0699 15.7882 22.5385L9 29.3267L2.21177 22.5385C1.74315 22.0699 0.983348 22.0699 0.514718 22.5385C0.0460892 23.0071 0.0460892 23.7669 0.514718 24.2356L8.15147 31.8723ZM7.8 10L7.8 31.0238H10.2L10.2 10H7.8Z"
                    fill="black"
                  />
                </svg>
              </button>
              

              <button
                className="buttonnew1 btn btn-1 btn-1d hndle"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                      <span>Download Plan</span>
              </button>
            </div>
          </div>
        </div>
      </section>{" "}
      <section id="about">
        <Reels />
      </section>
      <section id="madhur">
        <AboutSection />
      </section>
      <section id="amenities">
        <OrangeSection />
      </section>
      <section id="last">
        <LastCard />
      </section>
      <Footer />
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl d-flex justify-content-center align-items-center">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modalpopup">
                <div className="row">
                  <div className="col-md-6 col-sm-12 p-0 align-self-center">
                    <div className="pad">
                      <h6 className="h6">
                        {" "}
                        DOWNLOAD YOUR
                        PAYMENT PLAN

                        <div className="modal-footer-line"></div>
                      </h6>
                      <div className="modal-forminput">
                        <div className="modal-newletter">
                          <form onSubmit={setOtpbtn} action="#" className="modal-form">
                            <input
                              className="inp"
                              type="text"
                              required
                              placeholder="Name..."
                              onChange={(e) => setFullName(e.target.value)}
                              disabled={defaults ? true : false}
                            />

                            <input
                              className="inp"
                              type="tel"
                              maxLength="10"
                              minLength="10"
                              required
                              placeholder="Phone..."
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              disabled={defaults ? true : false}
                            />

                           

                            <button
                              className={
                                defaultscolor === true
                                  ? "defaultscolor"
                                  : "repair"
                              }
                            >
                              Download Plan
                            </button>

                            
                           
                          </form>
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 d-none d-md-block d-sm-none">
                    <img src={invest} alt="One Percent Scheme" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div
        class="modal fade"
        id="exampleModal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-xl d-flex justify-content-center align-items-center">
          <div class="modal-content">
            <div class="modal-body">
              <div className="modalpopup">
                <div className="row">
                <video autoPlay muted controls className="reel-video1">
                        <source src={unlockv1} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-xl d-flex justify-content-center align-items-center">
          <div class="modal-content">
            <div class="modal-body">
              <div className="modalpopup">
                <div className="row">
                <video autoPlay muted controls className="reel-video1">
                        <source src={unlockv2} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
      )}
    </>


    
  );
}


