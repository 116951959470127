import React from "react";
import PreLoader from "../../PreLoader.gif";

const Loader = () => {
  return (
    <div className="container-fluid load">
      <div className="row">
        <div className="col-12">
            <img src={PreLoader} alt="" />   
        </div>
      </div>
    </div>
   
  );
};

export default Loader;
