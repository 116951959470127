import React from "react";
import video from "../../assests/video/nadd.mp4";

const AboutSection = () => {
  return (
    <>
      <div className="container-fluid about-desc">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center justify-content-center">
            <div className="img-container-why">
              <div className="head">
                <h5 className="about-stoketext">
                  
                  <div className="gradient"></div> MA
                  <span className="per1"></span>DHUR DREAMS AT O
                  <span className="">
                    <span className="per"></span>NE PERCENT
                  </span>
                </h5>
              </div>
            </div>
            <div className="col-6"></div>
          </div>
          <div className="col-md-11 col-sm-12 mx-auto">
            <video autoPlay muted >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
