import React from "react";
import curve from "../../assests/orangeimg/curveorng.png";
import img1 from "../Footer/assests/01.jpg"
import img2 from "../Footer/assests/02.jpg"
import img3 from "../Footer/assests/03.jpg"
import img4 from "../Footer/assests/04.jpg"
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import onePer from "../../oneinvestor.png"
const LastCard = () => {
  const options = {
    autoplay: true,
    autoplayTimeout: 3000, // 3 seconds
    loop: true,
    margin: 20,
    nav: true,

    responsive: {
      0: {
        items: 1.2,
        margin: 10,
      },
      600: {
        items: 1.2,
      },
      1000: {
        items: 4,
      },
    },
  };

  return (
    <>
      <div className="container-fluid footer-card orange-bg">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center justify-content-center">
            <div className="img-container-why">
              <h5 className="about-stoketext">
                <div className="gradient"></div>
                INVESTORS AT O
                <span>
                  <span className="per"></span>NE PERCENT
                </span>
              </h5>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex reel-cont">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={20}
                responsive={options.responsive}
              >
                
                <div class="card__collection clear-fix">
                  <div class="cards cards--two">
                    <img
                      src={img1}
                      class="img-responsive cardd-img"
                      alt="One Percent Scheme"
                    />
                    <span class="cards--two__rect"></span>
                    <div className="p-code">
                    <p className="text-start">Aditya Agrawal</p>
                    <h6>Software Developer</h6>
                    </div>
                    <div className="crad-img">
                      <img src={onePer} alt="One Percent Scheme"/>
                    </div>

                  </div>
                </div>
                <div class="card__collection clear-fix">
                  <div class="cards cards--two">
                    <img
                      src={img2}
                      alt="One Percent Scheme"
                      className="cardd-img img-responsive"
                    />
                    <span class="cards--two__rect"></span>
                    <div className="p-code">
                    <p className="text-start">Aniket Singh</p>
                    <h6>Buisness Analyst</h6>
                    </div>
                    <div className="crad-img">
                      <img src={onePer} alt="One Percent Scheme"/>
                    </div>

                  </div>
                </div>
                <div class="card__collection clear-fix">
                  <div class="cards cards--two">
                    <img
                      src={img3}
                      alt="One Percent Scheme"
                      className="cardd-img img-responsive"
                    />
                    <span class="cards--two__rect"></span>
                    <div className="p-code">
                    <p className="text-start">Mohit Thakur</p>
                    <h6>Chartered Accountant</h6>
                    </div>
                    <div className="crad-img">
                      <img src={onePer} alt="One Percent Scheme"/>
                    </div>

                  </div>
                </div>
                <div class="card__collection clear-fix">
                  <div class="cards cards--two">
                    <img
                      src={img4}
                      alt="One Percent Scheme"
                      className="cardd-img img-responsive"
                    />
                    <span class="cards--two__rect"></span>
                    <div className="p-code">
                    <p className="text-start">Shashank Gupta</p>
                    <h6>Engineer</h6>
                    </div>
                    <div className="crad-img">
                      <img src={onePer} alt="One Percent Scheme"/>
                    </div>

                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LastCard;
