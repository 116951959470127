import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import reel3 from "./reel3.jpg";
import reel4 from "./reel4.jpg";
import reel5 from "./reel5.jpg";
import reel6 from "./reel6.jpg";
import reel7 from "./reel7.jpg";
import reelbtn from "./reelbtn.png";
import third from "../Media/third.mp4";
import fourth from "../Media/fourth.mp4";
import fifth from "../Media/fifth.mp4";
import sixth from "../Media/sixth.mp4";
import eighth from "../Media/eighth.mp4";

const Reels = () => {
  const [reelid, setReelId] = useState(0);
  const reels = [
    {
      id: "1",
      reelUrl: third,
      reelimg: reel5,
    },
    {
      id: "2",
      reelUrl: fourth,
      reelimg: reel3,
    },
    {
      id: "3",
      reelUrl: fifth,
      reelimg: reel4,
    },
    {
      id: "4",
      reelUrl: sixth,
      reelimg: reel6,
    },
    {
      id: "5",
      reelUrl: eighth,
      reelimg: reel7,
    },
  ];

  const options = {
    autoplay: true,
    autoplayTimeout: 2000, // 3 seconds
    loop: true,
    margin: 20,

    responsive: {
      0: {
        items: 1.7,
      },
      600: {
        items: 1.7,
      },
      1000: {
        items: 4.5,
      },
    },
  };

  return (
    <>
      <div className="container-fluid bg-black" id="about">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center justify-content-center">
            <div className="img-container-why">
              <h5 className="about-stoketext">
                {" "}
                INVEST WITH O<span className="per"></span>NE PERCENT
              </h5>
            </div>
          </div>
          
          <div className="col-12">
            <div className="d-flex reel-cont">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={20}
                autoplay
                responsive={options.responsive}
              >
                {reels.map((reelData, id) => {
                  return (
                    <>
                      <div className="reel-cards" key={id}>
                        <img
                          onClick={() => setReelId(id)}
                          src={reelbtn}
                          alt="One Percent Scheme"
                          className="reel-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        />
                        <img
                          className="reel-img"
                          src={reelData.reelimg}
                          alt="One Percent Scheme"
                        />
                      </div>
                    </>
                  );
                })}
              </OwlCarousel>
              <div className="gradient"></div>
            </div>
          </div>
         
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg d-flex justify-content-center align-items-center">
          <div className="modal-content w-50 bgg">
            <div className="modal-body1">
              {reels.map((rel, id)=>{
                return(
                  <>
                    {id == reelid? 
                    <>
                      <video autoPlay muted controls className="reel-video">
                        <source src={rel.reelUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </>
                    :
                    <></>
                    }
                  </>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};




export default Reels;
