import React from "react";
import img from "../../rotate1.png";
import Amenities from "../../Amenities.json";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const OrangeSection = () => {
  const options = {
    autoplay: true,
    autoplayTimeout: 3000, // 3 seconds
    loop: true,
    margin: 25,

    responsive: {
      0: {
        items: 1,
        margin: 0,
      },
      600: {
        items: 1,
        margin: 0,
      },
      1000: {
        items: 3.2,
      },
    },
  };

  return (
    <>
      <div className="separate"></div>
      <div className="container-fluid orange-bg1" id="orange-sec">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center justify-content-center">
            <div className="img-container-why">
              <h5 className="about-stoketext1">
              <div className="gradient"></div>
                AMENITIES AT MA<span className="per1"></span>DHUR DREAMS{" "}
              </h5>
            </div>
            <div className="col-6"></div>
          </div>
          <div className="row">
            <div className="col-12 background-about-stoketext">
              <div className="orange-banner background-about-stoketext-second">
                <OwlCarousel className="owl-theme " {...options}>
                  {Amenities.map((item, i) => {
                    return (
                      <>
                        <div className="content" key={i}>
                          <div className="grid">
                            <figure className="effect-sadie">
                              <img src={item.image} alt="img02" />
                              <figcaption >
                                <p>{item.Name}</p>
                              </figcaption>
                            </figure>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid orange-bg1" id="orange-sec">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center justify-content-center">
            <div className="img-container-why">
              <h5 className="about-stoketext1">
                EXPLORE MORE OF MA<span className="per1"></span>DHUR DREAMS{" "}
              </h5>
            </div>

            <div className="row">
              <div id="container">
                <div id="circles">
                  <a href="https://madhurdreams.com/" target="_">
                  <div className="dream-logo">
                    <img src={img} className="img-fluid circular-image" alt="One Percent Scheme"/>
                    <div className="circlelogo"></div>
                    
                  </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default OrangeSection;
